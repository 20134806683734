import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ModalOptionsInterface} from '../../interfaces/modal.interface';
import {ModalService} from '../../services/modal.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-rating-message-modal',
  templateUrl: './rating-message-modal.component.html',
  styleUrls: ['./rating-message-modal.component.sass']
})
export class RatingMessageModalComponent implements OnInit, OnDestroy {
  public events: Subject<any> = new Subject<any>();
  public options: ModalOptionsInterface;
  public modalService: ModalService;

  constructor(private router: Router) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.events = null;
  }

  public cancel = () => {
    this.events.next(false);
    this.modalService.closeModal();
  };
}
