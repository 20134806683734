import { ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { Router } from '@angular/router';
import { DoorsModalComponent } from '../components/doors-modal/doors-modal.component';
import { AccessAutomationsItemsComponent } from '../../dashboard/system/access-automations-items/access-automations-items.component';
import { AutomationsLogsStepsModalComponent } from '../components/automation-report-modal/automation-report-modal.component';
import { AuditLogDeltaModalComponent } from '../components/audit-log-delta-modal/audit-log-delta-modal.component';
import { RatingMessageModalComponent } from '../components/rating-message-modal/rating-message-modal.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class ModalService {
    constructor(componentFactoryResolver, router) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.router = router;
        this.showModal = (options) => {
            const confirmationModalComponent = this.componentFactoryResolver.resolveComponentFactory(ConfirmationModalComponent);
            const confirmationModalComponentRef = this.modalWrapper.createComponent(confirmationModalComponent);
            confirmationModalComponentRef.instance.options = options;
            confirmationModalComponentRef.instance.modalService = this;
            document.body.style.overflow = 'hidden';
            return confirmationModalComponentRef.instance.events;
        };
        this.showDoorsModal = (options) => {
            const confirmationModalComponent = this.componentFactoryResolver.resolveComponentFactory(DoorsModalComponent);
            const confirmationModalComponentRef = this.modalWrapper.createComponent(confirmationModalComponent);
            confirmationModalComponentRef.instance.options = options;
            confirmationModalComponentRef.instance.modalService = this;
            document.body.style.overflow = 'hidden';
            return confirmationModalComponentRef.instance.events;
        };
        this.showRatingMessageModal = (options) => {
            const ratingMessageModalComponent = this.componentFactoryResolver.resolveComponentFactory(RatingMessageModalComponent);
            const ratingMessageModalComponentRef = this.modalWrapper.createComponent(ratingMessageModalComponent);
            ratingMessageModalComponentRef.instance.options = options;
            ratingMessageModalComponentRef.instance.modalService = this;
            document.body.style.overflow = 'hidden';
            return ratingMessageModalComponentRef.instance.events;
        };
        this.showAutomationModal = (options) => {
            const automationModalComponent = this.componentFactoryResolver.resolveComponentFactory(AccessAutomationsItemsComponent);
            const automationModalComponentRef = this.modalWrapper.createComponent(automationModalComponent);
            automationModalComponentRef.instance.options = options;
            automationModalComponentRef.instance.modalService = this;
            document.body.style.overflow = 'hidden';
            return automationModalComponentRef.instance.events;
        };
        this.showAutomationReportModal = (options) => {
            const automationsLogsStepsModalComponent = this.componentFactoryResolver.resolveComponentFactory(AutomationsLogsStepsModalComponent);
            const automationsLogsStepsModalComponentRef = this.modalWrapper.createComponent(automationsLogsStepsModalComponent);
            automationsLogsStepsModalComponentRef.instance.options = options;
            automationsLogsStepsModalComponentRef.instance.modalService = this;
            document.body.style.overflow = 'hidden';
            return automationsLogsStepsModalComponentRef.instance.events;
        };
        this.showAuditLogDeltaModal = (options) => {
            const auditLogDeltaModalComponent = this.componentFactoryResolver.resolveComponentFactory(AuditLogDeltaModalComponent);
            const auditLogDeltaModalComponentRef = this.modalWrapper.createComponent(auditLogDeltaModalComponent);
            auditLogDeltaModalComponentRef.instance.options = options;
            auditLogDeltaModalComponentRef.instance.modalService = this;
            document.body.style.overflow = 'hidden';
            return auditLogDeltaModalComponentRef.instance.events;
        };
        this.closeModal = () => {
            this.modalWrapper.clear();
            document.body.style.overflow = 'inherit';
            if (this.router.url.includes('prospects/apartments' || 'residents/apartments')) {
                document
                    .getElementById('building-select')
                    .scrollIntoView({ block: 'center', behavior: 'smooth' });
            }
        };
    }
}
ModalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModalService_Factory() { return new ModalService(i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i1.Router)); }, token: ModalService, providedIn: "root" });
