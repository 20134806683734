import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalWrapperComponent} from './components/modal-wrapper/modal-wrapper.component';
import {ConfirmationModalComponent} from './components/confirmation-modal/confirmation-modal.component';
import {DoorsModalComponent} from './components/doors-modal/doors-modal.component';
import {AccessAutomationsItemsComponent} from '../dashboard/system/access-automations-items/access-automations-items.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {StoreModule} from '@ngrx/store';
import {reducersSystemDashboard} from '../dashboard/system/Store/reducers/dashboard-system.reducer';
import {EffectsModule} from '@ngrx/effects';
import {AccessAutomationsEffects} from '../dashboard/system/Store/effects/access-automations.effects';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AutomationsLogsStepsModalComponent} from './components/automation-report-modal/automation-report-modal.component';
import {AuditLogDeltaModalComponent} from './components/audit-log-delta-modal/audit-log-delta-modal.component';
import {RatingMessageModalComponent} from './components/rating-message-modal/rating-message-modal.component';

@NgModule({
  declarations: [
    ModalWrapperComponent,
    ConfirmationModalComponent,
    DoorsModalComponent,
    AccessAutomationsItemsComponent,
    AutomationsLogsStepsModalComponent,
    AuditLogDeltaModalComponent,
    RatingMessageModalComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatDialogModule,
    StoreModule.forFeature('dashboardSystem', reducersSystemDashboard),
    EffectsModule.forFeature([AccessAutomationsEffects]),
    FormsModule,
    MatProgressSpinnerModule,
    MatProgressSpinnerModule,
    MatProgressSpinnerModule
  ],
  exports: [ModalWrapperComponent],
  entryComponents: [
    ConfirmationModalComponent,
    DoorsModalComponent,
    AccessAutomationsItemsComponent,
    AutomationsLogsStepsModalComponent,
    AuditLogDeltaModalComponent,
    RatingMessageModalComponent
  ]
})
export class ModalModule {}
